import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router';

import selectAccount from 'state/selectors/accounts/accountSelector';
import selectMerchant from 'state/selectors/merchants/merchantSelector';

import KEYS from './keys';

const useGetUuid = () => {
  const location = useLocation();
  const isPwcMerchant = location.pathname.includes(KEYS.PWC_URL_PATH);

  const { account } = useSelector(selectAccount);
  const { merchant } = useSelector(selectMerchant);

  const match = useMatch(KEYS.PWC_ROUTE_MATCH ?? KEYS.RAMP_ROUTE_MATCH);
  const { uuid } = match?.params ?? { uuid: isPwcMerchant ? merchant.uuid : account.uuid };

  return uuid;
};

export default useGetUuid;
