import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import { SortOrder } from 'types/sort';

export const LABELS = {
  PANEL_TITLE: ['pages.payout.view.sections.transactions.title', 'Transactions'],
  TABLE_COLUMNS: {
    SOURCE_ID: ['pages.payout.view.sections.transactions.table.columns.name', 'Source ID'],
    TYPE: ['pages.payout.view.sections.transactions.table.columns.type', 'Type'],
    PROCESSING_FEE: ['pages.payout.view.sections.transactions.table.columns.processingFee', 'Processing Fee'],
    AMOUNT: ['pages.payout.view.sections.transactions.table.columns.amount', 'Amount'],
    DATE: ['pages.payout.view.sections.transactions.table.columns.date', 'Creation Time'],
    ACTIONS: ['pages.payout.view.sections.transactions.table.columns.actions', ''],
  },
  ACTIONS: {
    ORDER: {
      TITLE: ['pages.payout.view.sections.transactions.actions.title', 'Actions'],
      COPY_ORDER_ID: ['pages.payout.view.sections.transactions.actions.copy', 'Copy Order ID'],
      OPEN_DETAILS: ['pages.payout.view.sections.transactions.actions.openDetails', 'Open Order Details'],
    },
    MISSING: ['pages.payout.view.sections.transactions.actions.missing', 'Missing'],
  },
};

export const COLUMN_IDS = {
  TYPE: 'type',
  SOURCE_ID: 'source',
  AMOUNT: 'amount',
  PROCESSING_FEE: 'processingFee',
  ACTIONS: 'actions',
  CREATED_AT: 'createdAt',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.TYPE, label: t(LABELS.TABLE_COLUMNS.TYPE) },
  { id: COLUMN_IDS.SOURCE_ID, label: t(LABELS.TABLE_COLUMNS.SOURCE_ID) },
  { id: COLUMN_IDS.CREATED_AT, label: t(LABELS.TABLE_COLUMNS.DATE) },
  { id: COLUMN_IDS.AMOUNT, label: t(LABELS.TABLE_COLUMNS.AMOUNT) },
  { id: COLUMN_IDS.PROCESSING_FEE, label: t(LABELS.TABLE_COLUMNS.PROCESSING_FEE) },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const KEYS = {
  TABLE_DATE_FORMAT: 'MMM DD, YYYY HH:mm A',
  CURRENCY_PROP: 'currency',
  DEFAULT_TABLE_SORTING: {
    FIELD: COLUMN_IDS.CREATED_AT,
    DIRECTION: SortOrder.desc,
  },
};
