import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

export const LABELS = {
  PANEL_TITLE: ['pages.payout.view.sections.total.title', 'Payout Total'],
  TABLE_COLUMNS: {
    SOURCE_ID: ['pages.payout.view.sections.total.table.columns.name', 'Source ID'],
    TYPE: ['pages.payout.view.sections.total.table.columns.type', 'Type'],
    AMOUNT: ['pages.payout.view.sections.total.table.columns.amount', 'Amount'],
    COUNT: ['pages.payout.view.sections.total.table.columns.amount', 'Count'],
  },
};

export const COLUMN_IDS = {
  TYPE: 'type',
  AMOUNT: 'amount',
  COUNT: 'count',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.TYPE, label: t(LABELS.TABLE_COLUMNS.TYPE) },
  { id: COLUMN_IDS.COUNT, label: t(LABELS.TABLE_COLUMNS.COUNT) },
  { id: COLUMN_IDS.AMOUNT, label: t(LABELS.TABLE_COLUMNS.AMOUNT) },
];
