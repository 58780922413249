import { RequestPagination, ServerPagination } from 'types/pagination';
import { RequestSorting } from 'types/sort';

export interface PayoutsListFilters {
  merchantUuids?: string[];
}

export interface PayoutsListQuery {
  pagination?: RequestPagination;
  sort?: RequestSorting;
  filters?: PayoutsListFilters | undefined;
}

export enum PayoutDestinationType {
  BANK_ACCOUNT = 'bank_account',
  CRYPTO = 'crypto',
}

export enum BridgeTransferState {
  AWAITING_FUNDS = 'awaiting_funds',
  IN_REVIEW = 'in_review',
  FUNDS_RECEIVED = 'funds_received',
  PAYMENT_SUBMITTED = 'payment_submitted',
  PAYMENT_PROCESSED = 'payment_processed',
  CANCELED = 'canceled',
  ERROR = 'error',
  RETURNED = 'returned',
  REFUNDED = 'refunded',
}

export type Payout = {
  uuid: string;
  merchantUuid: string;
  periodStart: string;
  periodEnd: string;
  status: BridgeTransferState;
  destinationType: PayoutDestinationType;
  destinationUuid: string;
  destinationTracking: string;
  currency: string;
  amount: number;
  submittedAt: string;
};

export enum PayoutTotalType {
  ORDERS = 'Orders',
  FEES = 'Fees',
}

export enum PayoutTransactionType {
  ORDER = 'Order',
}

export interface PayoutDestination {
  status: BridgeTransferState;
  paymentRail: string;
  bankAccount?: {
    uuid: string;
    name: string;
    traceId: string;
  };
  crypto?: {
    wallet: string;
    onChain: {
      transactionHash: string;
      explorerUrl: string;
    };
  };
}

export interface PayoutPublicModel {
  uuid: string;
  merchantUuid: string;

  submittedAt: string;
  amount: number;
  currency: string;
  payoutDate: string;
  periodStart: string;
  periodEnd: string;
  totals: {
    type: PayoutTotalType;
    count: number;
    amount: number;
  }[];
  transactions: {
    type: PayoutTransactionType;
    source: string;
    amount: number;
    fee: number;
  }[];
  destination: PayoutDestination;
}

export type PayoutsListResponse = { data: Payout[]; pagination: ServerPagination };
export type PayoutInfoResponse = { data: PayoutPublicModel };
