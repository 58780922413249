import { PageWrapper } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetPwcOrderQuery } from 'services/PwcOrders';

import LoadingIndicator from 'components/loadingIndicator';

import RoutePath from 'router/path';

import { selectUserLevel } from 'state/slices/userSlice';

import { PaymentProvider, PwcOrderStatus } from '../../../types/pwcOrder';
import { useRedirectOnAccountSwitch } from '../../utils/useRedirectOnAccountSwitch';
import { LABELS } from './keys';
import { OrderDetails } from './orderDetails';
import { hasUserS4cRole } from './utils';

export const PwcOrderViewContainer = () => {
  const { t } = useTranslation();
  useRedirectOnAccountSwitch(RoutePath.Pwc.Orders.Root);

  const params = useParams();
  const { uuid } = params;

  const { data: orderPwc, isLoading } = useGetPwcOrderQuery(uuid!, {
    skip: !uuid,
  });

  const userLevel = useSelector(selectUserLevel);
  const isS4cUser = hasUserS4cRole(userLevel);

  const shouldShowExchangeDetailsSection = isS4cUser && orderPwc?.data?.general.status === PwcOrderStatus.Completed;
  const shouldShowSettlementDetailsSection =
    !isS4cUser &&
    orderPwc?.data?.cryptoPayment.paymentProvider === PaymentProvider.Manual &&
    (orderPwc?.data?.general.status === PwcOrderStatus.Completed || orderPwc?.data?.general.status === PwcOrderStatus.Pending);
  const shouldShowPaymentDetailsSection =
    orderPwc?.data?.general.status === PwcOrderStatus.Completed || orderPwc?.data?.general.status === PwcOrderStatus.Pending;
  const shouldShowPaymentSectionAdditionalFields = orderPwc?.data?.cryptoPayment.paymentProvider === PaymentProvider.Mesh;
  const shouldShowPayoutSection = orderPwc?.data?.general.status === PwcOrderStatus.Completed;
  // TODO: change when ready
  const showExtendedDetails = false;

  const hasExtendedAccess = isS4cUser;

  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  if (!orderPwc?.data) {
    return null;
  }

  return (
    <PageWrapper breadcrumbs={breadcrumbs} pageTitle={t(LABELS.PAGE_TITLE)}>
      <OrderDetails
        shouldShowPaymentSectionAdditionalFields={shouldShowPaymentSectionAdditionalFields}
        hasExtendedDetails={showExtendedDetails}
        shouldShowPaymentDetailsSection={shouldShowPaymentDetailsSection}
        orderPwcData={orderPwc?.data}
        shouldShowExchangeDetailsSection={shouldShowExchangeDetailsSection}
        hasExtendedAccess={hasExtendedAccess}
        shouldShowSettlementDetailsSection={shouldShowSettlementDetailsSection}
        shouldShowPayoutSection={shouldShowPayoutSection}
        isS4cUser={isS4cUser}
      />
    </PageWrapper>
  );
};
