import React from 'react';
import { PayoutPublicModel } from 'services/payouts/types';

import { DestinationSection } from './sections/destination/destination';
import { OrderSection } from './sections/details/payoutDetails';
import { TopAreaSection } from './sections/topArea/topArea';
import { TotalSection } from './sections/total/total';
import { TransactionsSection } from './sections/transactions/transactions';
import { Container, SubContainer } from './styles';

export interface OrderDetailsProps {
  payoutData: PayoutPublicModel;
  isLoading: boolean;
}

export const PayoutDetails = ({ payoutData, isLoading }: OrderDetailsProps) => {
  const sectionsProps = {
    payoutData,
    isLoading,
  };

  return (
    <>
      <TopAreaSection {...sectionsProps} />
      <Container>
        <SubContainer>
          <OrderSection {...sectionsProps} />
          <DestinationSection {...sectionsProps} />
        </SubContainer>

        <SubContainer>
          <TotalSection {...sectionsProps} />
        </SubContainer>
      </Container>
      <TransactionsSection {...sectionsProps} />
    </>
  );
};
