export const KEYS = {
  DATE_FORMAT: 'D MMM YYYY, h:mm A',
  DASH: '–',
};

export const LABELS = {
  BREADCRUMBS: [
    ['page.merchant.s4cUserBreadcrumbsMerchantAccounts', 'Merchant Accounts'],
    ['page.merchant.s4cUserBreadcrumbsAccountSettings', 'Account Settings'],
  ],
  PAGE_TITLE: ['pages.payout.view.title', 'Payout details'],
  SECTIONS: {
    DETAILS: {
      TITLE: ['page.payout.view.sections.details.title', 'Payout Details'],
      AMOUNT: ['page.payout.view.sections.details.amount', 'Amount'],
      DATE: ['page.payout.view.sections.details.date', 'Date'],
      PERIOD: ['page.payout.view.sections.details.period', 'Period'],
      PAYOUT_ID: ['page.payout.view.sections.details.id', 'Payout ID'],
      FROM: ['page.payout.view.sections.details.from', 'From: '],
      TO: ['page.payout.view.sections.details.to', 'To: '],
    },
    DESTINATION: {
      TITLE: ['pages.payout.view.sections.exchangeDetails.title', 'Payout Destination'],
      STATUS: ['pages.payout.view.sections.exchangeDetails.status', 'Status'],
      PAYMENT_RAIL: ['pages.payout.view.sections.exchangeDetails.paymentRail', 'Payment Rail'],
      BANK_ACCOUNT: ['pages.payout.view.sections.exchangeDetails.bankAccountName', 'Bank Account'],
      TRACE_ID: ['pages.payout.view.sections.exchangeDetails.traceId', 'Trace ID'],
      DESTINATION_WALLET: ['pages.payout.view.sections.exchangeDetails.destinationWallet', 'Destination Wallet'],
      TRANSACTION_HASH: ['pages.payout.view.sections.exchangeDetails.transactionHash', 'Transaction Hash'],
    },
    CUSTOMER: {
      TITLE: ['pages.payout.view.sections.customer.title', 'Customer'],
      CUSTOMER_EMAIL: ['pages.payout.view.sections.customer.userEmail', 'Customer Email'],
      CUSTOMER_ID: ['pages.payout.view.sections.customer.userId', 'Customer ID'],
      EXTERNAL_CUSTOMER_ID: ['pages.payout.view.sections.customer.externalUserId', 'External Customer ID'],
    },
    TOP_AREA: {
      PAYOUT_ID: ['pages.payout.view.sections.statusBar.id', 'Payout ID'],
      AMOUNT: ['pages.payout.view.sections.statusBar.amount', 'Amount'],
      STATUS: ['pages.payout.view.sections.statusBar.status', 'Status'],
    },
  },
};
