import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { capitalizeFirstLetter } from 'utils/string';

import { PayoutStatusBadge } from '../../../components/payoutStatusBadge/payoutStatusBadge';
import { KEYS, LABELS } from '../../keys';
import { PayoutSectionProps } from '../../types';
import { Item, ItemName, ItemValue, OnChainTransactionHash, SectionWrapper } from '../styles';

export const DestinationSection = ({ payoutData }: PayoutSectionProps) => {
  const { t } = useTranslation();

  const { destination } = payoutData;

  const isBankTransaction = Boolean(destination.bankAccount);
  const isChainTransaction = Boolean(destination.crypto);

  return (
    <Panel label={t(LABELS.SECTIONS.DESTINATION.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.DESTINATION.PAYMENT_RAIL)}</ItemName>
          <ItemValue>{destination.paymentRail ? capitalizeFirstLetter(destination.paymentRail) : KEYS.DASH}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.DESTINATION.STATUS)}</ItemName>
          <ItemValue>
            <PayoutStatusBadge status={payoutData.destination.status} />
          </ItemValue>
        </Item>

        <OptionallyVisible visible={isChainTransaction}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.DESTINATION.DESTINATION_WALLET)}</ItemName>
            <ItemValue>{payoutData?.destination.crypto?.wallet}</ItemValue>
          </Item>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.DESTINATION.TRANSACTION_HASH)}</ItemName>
            <OnChainTransactionHash displayFullHash>
              <a href={payoutData.destination?.crypto?.onChain?.explorerUrl} target="_blank">
                {payoutData.destination?.crypto?.onChain?.transactionHash}
              </a>
            </OnChainTransactionHash>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={isBankTransaction}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.DESTINATION.BANK_ACCOUNT)}</ItemName>
            <ItemValue>{payoutData.destination?.bankAccount?.name}</ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.DESTINATION.TRACE_ID)}</ItemName>
            <ItemValue>{payoutData.destination?.bankAccount?.traceId}</ItemValue>
          </Item>
        </OptionallyVisible>
      </SectionWrapper>
    </Panel>
  );
};
