import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';
import { BadgeType } from 'components/elements/badge/types';

export const KEYS = {
  EMAIL_FIELD: 'email',
};

export const LABELS = {
  RESEND_ERROR_TITLE: ['components.accountSettings.users.resendErrorTitle', 'Resend Invitation'],
  REVOKE_ERROR_TITLE: ['components.accountSettings.users.revokeErrorTitle', 'Revoke Invitation'],
  PANEL_TITLE: ['components.accountSettings.users.title', 'Users'],
  INVITE_USER: ['components.accountSettings.users.inviteUser', 'INVITE USER'],
  ACTIONS: {
    NO_ACTIONS_AVAILABLE: ['components.accountSettings.users.actions.noActionsAvailable', 'No available actions'],
    TITLE: ['components.accountSettings.users.actions.title', 'Actions'],
    EDIT: ['components.accountSettings.users.actions.edit', 'Edit'],
    RESEND_INVITE: ['components.accountSettings.users.actions.resendInvite', 'Resend Invite'],
    REVOKE_ACCESS: ['components.accountSettings.users.actions.revokeAccess', 'Revoke Access'],
  },
  STATUS: {
    ACCEPTED: ['components.accountSettings.users.status.accepted', 'Active'],
    PENDING: ['components.accountSettings.users.status.pending', 'Pending Invitation'],
  },
  EDIT_MODAL: {
    TITLE: ['components.accountSettings.users.editModal.title', 'Edit User'],
    CONFIRM_TITLE: ['components.accountSettings.users.editModal.confirmTitle', 'Are you sure?'],
    CONFIRM_MESSAGE: [
      'components.accountSettings.users.editModal.confirmMessage',
      'You are granting ‘Owner’ user permissions to another user. This will transfer ownership of the account.',
    ],
    BUTTONS: {
      EDIT: ['components.accountSettings.users.editModal.buttons.edit', 'Edit'],
      CANCEL: ['components.accountSettings.users.editModal.buttons.cancel', 'Cancel'],
      CONFIRM: ['components.accountSettings.users.editModal.buttons.confirm', 'Confirm'],
    },
    EMAIL: {
      TITLE: ['components.accountSettings.users.editModal.email.title', 'User email'],
      PLACEHOLDER: ['components.accountSettings.users.editModal.email.placeholder', 'E-mail'],
    },
    PERMISSIONS: {
      LABEL: ['components.accountSettings.users.editModal.permissions.label', 'Select user permissions'],
      SUPER_ADMIN: {
        NAME: ['components.accountSettings.users.editModal.permissions.superAdmin.name', 'Owner'],
        CAPTION: ['components.accountSettings.users.editModal.permissions.superAdmin.caption', 'Can see and manage everything'],
      },
      ADMIN: {
        NAME: ['components.accountSettings.users.editModal.permissions.admin.name', 'Administrator'],
        CAPTION: ['components.accountSettings.users.editModal.permissions.admin.caption', 'Can see and manage everything'],
      },
      SUPPORT: {
        NAME: ['components.accountSettings.users.editModal.permissions.support.name', 'Viewer'],
        CAPTION: [
          'components.accountSettings.users.editModal.permissions.support.caption',
          'Can see everything except no account settings',
        ],
      },
    },
  },
  REMOVE_MODAL: {
    TITLE: ['components.accountSettings.users.removeModal.title', 'Revoke Access'],
    QUESTION: ['components.accountSettings.users.removeModal.question', 'Are you sure?'],
    CAPTION: ['components.accountSettings.users.removeModal.caption', 'Remove {{email}} access from your account.'],
    BUTTONS: {
      REMOVE: ['components.accountSettings.users.removeModal.buttons.remove', 'Confirm'],
      CANCEL: ['components.accountSettings.users.removeModal.buttons.cancel', 'Cancel'],
    },
  },
  INVITE_MODAL: {
    TITLE: ['components.accountSettings.users.inviteModal.title', 'Invite User'],
    BUTTONS: {
      INVITE: ['components.accountSettings.users.inviteModal.buttons.invite', 'Invite'],
      CANCEL: ['components.accountSettings.users.inviteModal.buttons.cancel', 'Cancel'],
    },
    EMAIL: {
      TITLE: ['components.accountSettings.users.inviteModal.email.title', 'Enter user email'],
      PLACEHOLDER: ['components.accountSettings.users.inviteModal.email.placeholder', 'E-mail'],
    },
    PERMISSIONS: {
      LABEL: ['components.accountSettings.users.inviteModal.permissions.label', 'Select user permissions'],
      SUPER_ADMIN: {
        NAME: ['components.accountSettings.users.inviteModal.permissions.superAdmin.name', 'Owner'],
        CAPTION: ['components.accountSettings.users.inviteModal.permissions.superAdmin.caption', 'Can see and manage everything'],
      },
      ADMIN: {
        NAME: ['components.accountSettings.users.inviteModal.permissions.admin.name', 'Administrator'],
        CAPTION: ['components.accountSettings.users.inviteModal.permissions.admin.caption', 'Can see and manage everything'],
      },
      SUPPORT: {
        NAME: ['components.accountSettings.users.inviteModal.permissions.support.name', 'Viewer'],
        CAPTION: [
          'components.accountSettings.users.inviteModal.permissions.support.caption',
          'Can see everything except no account settings',
        ],
      },
    },
  },
  TABLE_COLUMNS: {
    EMAIL: ['components.accountSettings.users.table.columns.email', 'Email'],
    ROLES: ['components.accountSettings.users.table.columns.roles', 'Roles'],
    STATUS: ['components.accountSettings.users.table.columns.status', 'Status'],
    ACTIONS: ['components.accountSettings.users.table.columns.actions', ''],
  },
};

export const COLUMN_IDS = {
  ID: 'uuid',
  EMAIL: 'username',
  ROLES: 'level',
  STATUS: 'invitedStatus',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.EMAIL, label: t(LABELS.TABLE_COLUMNS.EMAIL), sortable: true },
  { id: COLUMN_IDS.ROLES, label: t(LABELS.TABLE_COLUMNS.ROLES), sortable: true },
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const badgeTypeMap: Record<string, BadgeType> = {
  pending: BadgeType.Default,
  accepted: BadgeType.Success,
};
