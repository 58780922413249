import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store';

const selectMerchantHandler = (state: RootState) => {
  return {
    merchant: state.merchant.merchant,
  };
};

export const selectMerchant = createSelector([(state: RootState) => state], selectMerchantHandler);

export default selectMerchant;
