import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BridgeTransferState } from 'services/payouts/types';

import { Badge } from 'components/elements/badge/badge';
import { BadgeType } from 'components/elements/badge/types';
import IconChecked from 'components/icons/checked';
import IconCross from 'components/icons/cross';
import IconRefresh from 'components/icons/refresh';

import { BadgeContent } from './styles';

interface StatusBadgeProps {
  status: BridgeTransferState;
}

export const payoutStatusBadgeTypeMap: Partial<Record<BridgeTransferState, BadgeType>> = {
  [BridgeTransferState.PAYMENT_PROCESSED]: BadgeType.Success,
  [BridgeTransferState.AWAITING_FUNDS]: BadgeType.Default,
  [BridgeTransferState.IN_REVIEW]: BadgeType.Default,
  [BridgeTransferState.FUNDS_RECEIVED]: BadgeType.Default,
  [BridgeTransferState.PAYMENT_SUBMITTED]: BadgeType.Default,
  [BridgeTransferState.CANCELED]: BadgeType.Fail,
  [BridgeTransferState.ERROR]: BadgeType.Fail,
  [BridgeTransferState.RETURNED]: BadgeType.Fail,
  [BridgeTransferState.REFUNDED]: BadgeType.Fail,
};

const badgeToIconMap: Record<BridgeTransferState, ReactElement | null> = {
  [BridgeTransferState.PAYMENT_PROCESSED]: <IconChecked />,
  [BridgeTransferState.AWAITING_FUNDS]: <IconRefresh />,
  [BridgeTransferState.IN_REVIEW]: <IconRefresh />,
  [BridgeTransferState.FUNDS_RECEIVED]: <IconRefresh />,
  [BridgeTransferState.PAYMENT_SUBMITTED]: <IconRefresh />,
  [BridgeTransferState.CANCELED]: <IconCross />,
  [BridgeTransferState.ERROR]: <IconCross />,
  [BridgeTransferState.RETURNED]: <IconCross />,
  [BridgeTransferState.REFUNDED]: <IconCross />,
};

const statusToLabelMap: Record<BridgeTransferState, string[]> = {
  [BridgeTransferState.PAYMENT_PROCESSED]: ['components.payoutStatusBadge.status', 'Payment Processed'],
  [BridgeTransferState.AWAITING_FUNDS]: ['components.payoutStatusBadge.status', 'Awaiting Funds'],
  [BridgeTransferState.IN_REVIEW]: ['components.payoutStatusBadge.status', 'In Review'],
  [BridgeTransferState.FUNDS_RECEIVED]: ['components.payoutStatusBadge.status', 'Funds Received'],
  [BridgeTransferState.PAYMENT_SUBMITTED]: ['components.payoutStatusBadge.status', 'Payment Submitted'],
  [BridgeTransferState.ERROR]: ['components.payoutStatusBadge.status', 'Error'],
  [BridgeTransferState.RETURNED]: ['components.payoutStatusBadge.status', 'Returned'],
  [BridgeTransferState.REFUNDED]: ['components.payoutStatusBadge.status', 'Refunded'],
  [BridgeTransferState.CANCELED]: ['components.payoutStatusBadge.status', 'Canceled'],
};

export const PayoutStatusBadge = ({ status }: StatusBadgeProps) => {
  const { t } = useTranslation();

  const badgeType = payoutStatusBadgeTypeMap[status] || BadgeType.Default;
  const icon = badgeToIconMap[status];
  const label = t(statusToLabelMap[status]);

  return (
    <Badge type={badgeType}>
      <BadgeContent>
        {icon}
        {label}
      </BadgeContent>
    </Badge>
  );
};
