import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { PayoutStatusBadge } from '../../../components/payoutStatusBadge/payoutStatusBadge';
import { LABELS } from '../../keys';
import { PayoutSectionProps } from '../../types';
import { formatNumber } from '../utils';
import { Delimiter, Item, ItemName, ItemValue, TopAreaContainer } from './styles';

export const TopAreaSection = ({ payoutData }: PayoutSectionProps) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <TopAreaContainer>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.PAYOUT_ID)}:</ItemName>
          <ItemValue>{payoutData.uuid}</ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.AMOUNT)}:</ItemName>
          <ItemValue upper>
            {formatNumber(payoutData.amount)} {payoutData.currency}
          </ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.TOP_AREA.STATUS)}:</ItemName>
          <ItemValue>
            <PayoutStatusBadge status={payoutData.destination.status} />
          </ItemValue>
        </Item>
      </TopAreaContainer>
    </Panel>
  );
};
