import { PayoutPublicModel } from 'services/payouts/types';

import i18n from 'locales/i18next';

import { formatNumberI18n } from 'utils/i18n';

import { TransactionDataRow } from './types';

export const transformTotalsDataToTableFormat = (
  currency: PayoutPublicModel['currency'],
  payouts?: PayoutPublicModel['totals'],
): TransactionDataRow[] => {
  return payouts
    ? payouts.map((total, index) => ({
        ...total,
        id: index,
        currency,
      }))
    : [];
};
export const formatNumber = (stringOrNumber?: string | number) => formatNumberI18n(i18n.language)(Number(stringOrNumber ?? 0));
