import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { useGetAccountApiKeysQuery } from 'services/apiKeys';
import { useGetMerchantQuery, useUpdateMerchantMutation } from 'services/merchants';
import { MerchantCheckoutSettings } from 'services/merchants/types';
import { useUploadAssetMutation } from 'services/staticAssets';

import { FormFieldType } from 'components/form/formLayout/types';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { ValidationErrorMeta } from 'types/error';
import { UserAccessLevel } from 'types/user';

import { getValidationDetails } from 'utils/error';

import { getFormValues } from '../../utils';
import { KEYS, LABELS, formFields } from './keys';
import { CheckoutSection } from './landing';

export const CheckoutSectionContainer = () => {
  const { t } = useTranslation();
  const match = useMatch(KEYS.ROUTE_MATCH);
  const { account } = useSelector(selectAccount);
  const { uuid } = match?.params ?? { uuid: account.uuid };

  const { data: merchantResponse } = useGetMerchantQuery(uuid!, {
    skip: !uuid,
    refetchOnMountOrArgChange: true,
  });
  const [merchantInitialData, setMerchantInitialData] = useState(() => {
    return getFormValues(merchantResponse?.data, formFields) as MerchantCheckoutSettings;
  });
  const [formValues, setFormValues] = useState<MerchantCheckoutSettings>(merchantInitialData);
  const [saveMerchantInfo, { isLoading: isUpdating, error, reset }] = useUpdateMerchantMutation();
  const [previewSeed, setPreviewSeed] = useState<string>(Math.random().toString());

  const [uploadAsset, { isLoading: isAssetUploading, reset: resetUpload }] = useUploadAssetMutation();
  const userLevel = useSelector(selectUserLevel);
  const { data } = useGetAccountApiKeysQuery(uuid!, {
    skip: !uuid,
  });
  const [titleVisible, setTitleVisible] = useState(Boolean(merchantResponse?.data.title));

  useEffect(() => {
    if (!merchantResponse) {
      return;
    }

    setFormValues(getFormValues(merchantResponse?.data, formFields) as MerchantCheckoutSettings);
    setTitleVisible(Boolean(merchantResponse?.data.title));
    setMerchantInitialData(getFormValues(merchantResponse?.data, formFields) as MerchantCheckoutSettings);
  }, [uuid, merchantResponse]);

  const toggleTitleVisible = () => {
    setTitleVisible((prev) => !prev);
  };

  const handleFormValueChange = (key: string, value: any) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    reset();
    resetUpload();
  };

  const readOnlyMode = Boolean(userLevel && userLevel === UserAccessLevel.Support);

  const resetFormValues = () => {
    setFormValues(getFormValues(merchantResponse?.data, formFields) as MerchantCheckoutSettings);
    reset();
    resetUpload();
    setTitleVisible(Boolean(merchantResponse?.data.title));
    setPreviewSeed(Math.random().toString());
  };

  const uploadFormFile = async (fieldName: keyof MerchantCheckoutSettings): Promise<boolean | { [field: string]: string }> => {
    const isFileUpdated = !isEqual(merchantResponse?.data[fieldName], formValues[fieldName]);
    const isFile = formValues[fieldName] instanceof File;
    const shouldUploadFile = isFileUpdated && isFile;
    if (shouldUploadFile) {
      const response = await uploadAsset(formValues[fieldName] as File);
      if ('error' in response) {
        return false;
      }

      return { [fieldName]: response.data };
    }

    return true;
  };

  const uploadFormFiles = async (): Promise<boolean | { [field: string]: string }> => {
    const fileFields = formFields.filter((field) => field.type === FormFieldType.File);
    const promises = fileFields.map((field) => {
      const fieldName = field.key as keyof MerchantCheckoutSettings;
      return uploadFormFile(fieldName);
    });

    const result = await Promise.all(promises);
    if (!result.every(Boolean)) {
      return false;
    }

    return result.reduce((output, response) => {
      if (typeof response === 'object') {
        return {
          ...(output as object),
          ...response,
        };
      }

      return output;
    }, {});
  };

  const saveFormValues = async () => {
    if (readOnlyMode) {
      return;
    }

    const changedValues = await uploadFormFiles();
    if (!changedValues) {
      return;
    }

    const payload: Partial<MerchantCheckoutSettings> = {
      ...formValues,
      title: titleVisible ? formValues?.title : null,
      ...(changedValues as object),
    };

    const preparedValues = { ...payload, uuid };

    await saveMerchantInfo(preparedValues);
    setPreviewSeed(Math.random().toString());
    setFormValues({
      ...(payload as MerchantCheckoutSettings),
    });
    setMerchantInitialData({
      ...(payload as MerchantCheckoutSettings),
    });

    if (!formValues?.title?.length) {
      setTitleVisible(false);
    }
  };

  const hasFormChanged = () => {
    if (titleVisible) {
      return !isEqual(formValues, merchantInitialData);
    }

    return titleVisible !== Boolean(merchantInitialData.title) || !isEqual(formValues, merchantInitialData);
  };

  const isFormChanged = hasFormChanged();
  const saveErrorMeta = error ? ((error as FetchBaseQueryError).data as { meta: ValidationErrorMeta })?.meta : undefined;
  const saveError = saveErrorMeta ? getValidationDetails(saveErrorMeta) : undefined;
  const possibleFiles = formFields.reduce((output, field) => {
    if (field.type !== FormFieldType.File) {
      return output;
    }

    return {
      ...output,
      [field.key]: formValues[field.key as keyof MerchantCheckoutSettings],
    };
  }, {});

  const fileErrors = Object.entries(possibleFiles).reduce((result, [key, value]) => {
    const shouldAddError = value instanceof File && value.size > KEYS.MAX_LOGO_SIZE;
    if (shouldAddError) {
      return {
        ...result,
        [key]: t(LABELS.FORM_FIELDS.ERRORS.MAX_FILE_SIZE),
      };
    }

    return result;
  }, {});
  const displayedError = saveError || fileErrors;
  const isSaveAvailable = isFormChanged && isEmpty(displayedError);

  return (
    <CheckoutSection
      account={formValues}
      onFormValueChange={handleFormValueChange}
      isFormChanged={isFormChanged}
      isSaveAvailable={isSaveAvailable}
      resetFormValues={resetFormValues}
      saveFormValues={saveFormValues}
      error={displayedError}
      isUpdating={isUpdating || isAssetUploading}
      readOnlyMode={readOnlyMode}
      previewOptions={{
        publicKey: data?.publicKey,
        seed: previewSeed,
      }}
      titleProps={{
        titleVisible,
        toggleTitleVisible,
      }}
    />
  );
};
