export interface PwcOrder extends CryptoBase, GeneralOrderBase, CryptoPaymentBase {
  merchantUuid: string;
  paymentProviderId: string;
  onChainTransactionHash: string;
  fromWallet: string;
  network: string;
  summary: string;
  customerUuid: string;
  customerEmail: string;
  assetUuid: string;
  depositUuid: string;
  settlementDestination: string;
  settlementOnchainTransactionHash: string;
  externalCustomerId: string;
}

export enum PaymentProvider {
  Manual = 'manual',
  Mesh = 'mesh',
  WalletConnect = 'walletConnect',
}

export enum LiquidityProvider {
  Bridge = 'bridge',
}

export enum PwcOrderStatus {
  Initiated = 'INITIATED',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED',
}

export enum Network {
  Base = 'base',
  Ethereum = 'ethereum',
}

export interface CryptoBase {
  liquidityProvider: LiquidityProvider | null;
  toWalletAddress: string | null;
  toWalletMemo: string | null;
  settlementStatus: string | null;
  settlementPaymentRail: string | null;
  settlementCurrency: string | null;
  bankAccount?: {
    uuid: string;
    bankName: string;
  };
  crypto?: {
    wallet: string | null;
    onChain: {
      transactionHash: string;
      explorerUrl: string;
    } | null;
  };
}

export interface CryptoExchange extends CryptoBase {
  depositReceivedAt: string | null;
}

export interface OnChainDetails {
  onChain?: {
    transactionHash: string;
    explorerUrl: string;
  };
}

export interface OrderItem {
  uuid: string;
  name: string;
  imageUrl: string | null;
  fiatAmount: number;
  orderUuid: string;
}

export interface GeneralOrderBase {
  uuid: string;
  createdAt: string;
  expiredAt: string;
  status: PwcOrderStatus;
  cryptoCurrencyCode: string | null;
  cryptoAmount: string | null;
  fiatCurrencyCode: string | null; // can't be null?
  fiatAmount: string | number | null; // can't be null?
  processingFeePercent: string | null;
  processingFeeAmount: string | null;
  cryptoPrice: string | null;
  redirectUrl: string | null;
  cancelRedirectUrl: string | null;
}

export interface GeneralOrder extends GeneralOrderBase {
  externalOrderId: string | null;
  items: OrderItem[];
}

export interface CryptoPaymentBase {
  paymentProvider: PaymentProvider | null;
  paymentProviderConnectionName: string | null;
  paymentProviderConnectionType: string | null;
  paymentProviderStatus: string | null;
  paymentProviderStatusUpdatedAt: string | null;

  paymentProviderId: string | null;
}

export interface CryptoPayment extends CryptoPaymentBase, OnChainDetails {
  cryptoCurrencyCode: string;
  network?: Network;
  fromWalletAddress: string;
}

export type CryptoAsset = {
  uuid: string;
  name: string;
  network: string;
  cryptoCurrencyCode: string;
  meshNetworkId: string;
  minAmount: number;
  uniqueDepositAddressEnabled: boolean;
  isEvm: boolean;
  iconUrl: string;
  cryptoNetworkIconUrl: string;
};

export type MerchantOrder = {
  uuid: string;
  name: string;
};

export type MerchantCustomer = {
  uuid: string;
  email: string | null;
  externalCustomerId: string | null;
};
