import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';

import { CryptoPayment } from 'types/pwcOrder';

import { convertValueToDayjs } from 'utils/date';
import { capitalizeFirstLetter } from 'utils/string';

import { KEYS, LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import { CryptoBlock, Image, Item, ItemName, ItemValue, OnChainTransactionHash, SectionWrapper } from '../styles';
import { formatCryptoCode } from '../utils';

interface PaymentSectionProps extends OrderPwcSectionProps {
  shouldShowPaymentSectionAdditionalFields: boolean;
}

export const PaymentSection = ({ orderData, shouldShowPaymentSectionAdditionalFields }: PaymentSectionProps) => {
  const { t } = useTranslation();

  const cryptoPayment = orderData?.cryptoPayment as CryptoPayment;

  return (
    <Panel label={t(LABELS.SECTIONS.PAYMENT.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.CRYPTOCURRENCY)}</ItemName>
          <ItemValue>
            <CryptoBlock>
              <Image src={orderData.cryptoAsset?.iconUrl} /> {formatCryptoCode(cryptoPayment.cryptoCurrencyCode)}
            </CryptoBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.NETWORK)}</ItemName>
          <ItemValue>{cryptoPayment.network ? capitalizeFirstLetter(cryptoPayment.network) : t(KEYS.DASH)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_PROVIDER)}</ItemName>
          <ItemValue>{cryptoPayment.paymentProvider ? capitalizeFirstLetter(cryptoPayment.paymentProvider) : t(KEYS.DASH)}</ItemValue>
        </Item>

        <OptionallyVisible visible={shouldShowPaymentSectionAdditionalFields}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_PROVIDER_ID)}</ItemName>
            <ItemValue>{cryptoPayment.paymentProviderId}</ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_CONNECTION_TYPE)}</ItemName>
            <ItemValue>{cryptoPayment.paymentProviderConnectionType}</ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_CONNECTION_NAME)}</ItemName>
            <ItemValue>{cryptoPayment.paymentProviderConnectionName}</ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.PAYMENT_STATUS)}</ItemName>
            <ItemValue>
              {cryptoPayment.paymentProviderStatus ? capitalizeFirstLetter(cryptoPayment.paymentProviderStatus) : t(KEYS.DASH)}
            </ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.PAYMENT.EXECUTED_TIME)}</ItemName>
            <ItemValue>{convertValueToDayjs(cryptoPayment.paymentProviderStatusUpdatedAt)?.format(KEYS.DATE_FORMAT)}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.DEPOSIT_ADDRESS)}</ItemName>
          <ItemValue>{cryptoPayment.fromWalletAddress}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.PAYMENT.TRANSACTION_HASH)}</ItemName>
          <OptionallyVisible visible={Boolean(cryptoPayment.onChain?.explorerUrl)}>
            <OnChainTransactionHash displayFullHash>
              <a href={cryptoPayment.onChain?.explorerUrl} target="_blank">
                {cryptoPayment.onChain?.transactionHash}
              </a>
            </OnChainTransactionHash>
          </OptionallyVisible>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
