import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';
import { CreateUserPayload, CreateUserResponse } from 'services/users/types';

import { AccountAccessInfo, AccountType, ApplePayDomainDescription } from 'types/account';
import { ErrorResponse, HTTPMethod, Response, SuccessResponse } from 'types/http';
import { UserRecord } from 'types/user';

import { getDefaultPagination } from '../../utils/pagination';
import { fetchBaseQueryWithAuth } from '../api';
import merchantsApi from '../merchants';
import { TAGS as MERCHANT_API_TAGS } from '../merchants/keys';
import usersApi from '../users';
import { TAGS as USERS_API_TAGS } from '../users/keys';
import { TAGS } from './keys';
import {
  AccountInfoResponse,
  AccountUpdateRequestBody,
  AccountUpdateResponse,
  AccountUserAccessLevelUpdateRequestBody,
  AccountUserAccessLevelUpdateResponse,
  AccountUsersResponse,
  AccountsListQuery,
  AccountsListResponse,
  AddApplePayDomainRequest,
  InviteUserPayload,
  InviteUserResponse,
  RemoveApplePayDomainRequest,
  ResendInvitePayload,
  ResendInviteResponse,
  RevokeInvitePayload,
  RevokeInviteResponse,
} from './types';
import { decorateUpdateRequest } from './utils';

const REDUCER_PATH = 'accountsApi';

export const accountsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.ACCOUNT_LIST, TAGS.DOMAIN_LIST],
  endpoints: (builder) => ({
    getAccountsList: builder.query<AccountsListResponse, AccountsListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Accounts.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<AccountsListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination(), requestId: response.requestId };
        }

        return response.data;
      },
      providesTags: [TAGS.ACCOUNT_LIST],
    }),
    getAccount: builder.query<AccountInfoResponse, string>({
      query: (uuid) => Endpoint.Accounts.ById(uuid),
      forceRefetch: () => true,
    }),
    updateAccount: builder.mutation<AccountUpdateResponse, AccountUpdateRequestBody>({
      query: (requestBody) => ({
        url: Endpoint.Accounts.ById(requestBody.uuid!),
        method: HTTPMethod.PUT,
        body: decorateUpdateRequest(requestBody),
      }),
    }),
    updateAccountUserAccess: builder.mutation<AccountUserAccessLevelUpdateResponse, AccountUserAccessLevelUpdateRequestBody>({
      query: (requestBody) => ({
        url: Endpoint.Accounts.AccountUserAccessLevel(requestBody.accountUuid, requestBody.userUuid),
        method: HTTPMethod.POST,
        body: { level: requestBody.level },
      }),
      invalidatesTags: (result, error) => (error ? [] : [TAGS.USERS_LIST]),
    }),
    resendInvite: builder.mutation<ResendInviteResponse, ResendInvitePayload>({
      query: (requestBody) => ({
        url: Endpoint.Users.ResendInvite,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      invalidatesTags: (result, error) => (error ? [] : [TAGS.USERS_LIST]),
    }),
    revokeInvite: builder.mutation<RevokeInviteResponse, RevokeInvitePayload>({
      query: ({ isRevokingSelf, ...requestBody }) => ({
        url: Endpoint.Users.RevokeAccess,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      invalidatesTags: (result, error) => (error ? [] : [TAGS.USERS_LIST]),
      onQueryStarted: async ({ isRevokingSelf }, { dispatch, queryFulfilled }): Promise<void> => {
        await queryFulfilled;
        if (isRevokingSelf) {
          dispatch(usersApi.util.invalidateTags([USERS_API_TAGS.USER_ACCOUNT_LIST]));
        }
      },
    }),
    inviteUser: builder.mutation<InviteUserResponse, InviteUserPayload>({
      query: (requestBody) => ({
        url: Endpoint.Users.Invite,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      invalidatesTags: (result, error) => (error ? [] : [TAGS.USERS_LIST]),
    }),
    getAccountUsers: builder.query<{ user: UserRecord; access: AccountAccessInfo }[], string>({
      query: (uuid) => Endpoint.Accounts.UserByAccountId(uuid),
      transformResponse: (response: AccountUsersResponse) => {
        if (!response?.data) {
          return [];
        }

        return response.data.users;
      },
      providesTags: [TAGS.USERS_LIST],
    }),
    createAccount: builder.mutation<CreateUserResponse, CreateUserPayload>({
      query: (requestBody) => ({
        url: Endpoint.Users.Create,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      invalidatesTags: (result, error) => (error ? [] : [TAGS.ACCOUNT_LIST]),
      onQueryStarted: async ({ accountType }, { dispatch, queryFulfilled }): Promise<void> => {
        await queryFulfilled;
        if (accountType === AccountType.Pwc) {
          dispatch(merchantsApi.util.invalidateTags([MERCHANT_API_TAGS.MERCHANT_LIST]));
        }
      },
    }),
    getApplePayDomains: builder.query<ApplePayDomainDescription[], string>({
      query: (uuid: string) => Endpoint.Accounts.ApplePayDomains(uuid),
      providesTags: [TAGS.DOMAIN_LIST],
      transformResponse: (response: Response<{ domains: ApplePayDomainDescription[] }>) => {
        if (!response?.data) {
          return [];
        }

        return response.data.domains;
      },
    }),
    addApplePayDomain: builder.mutation<ApplePayDomainDescription | null, AddApplePayDomainRequest>({
      query: ({ domain, accountUuid }) => ({
        url: Endpoint.Accounts.ApplePayDomains(accountUuid),
        method: HTTPMethod.POST,
        body: {
          domain,
        },
      }),
      invalidatesTags: [TAGS.DOMAIN_LIST],
      transformResponse: (response: Response<{ domain: ApplePayDomainDescription }>) => {
        if (!response?.data) {
          return null;
        }

        return response.data.domain;
      },
      transformErrorResponse: (response): ErrorResponse => {
        return response.data as ErrorResponse;
      },
    }),
    removeApplePayDomain: builder.mutation<boolean, RemoveApplePayDomainRequest>({
      query: ({ domainUuid, accountUuid }) => ({
        url: Endpoint.Accounts.ApplePayDomains(accountUuid),
        method: HTTPMethod.DELETE,
        body: {
          domainUuid,
        },
      }),
      invalidatesTags: [TAGS.DOMAIN_LIST],
      transformResponse: (response: Response<SuccessResponse>) => {
        return Boolean(response?.data?.success);
      },
      transformErrorResponse: (response): ErrorResponse => {
        return response.data as ErrorResponse;
      },
    }),
  }),
});

export const { getAccountsList, getAccount, updateAccount, getAccountUsers } = accountsApi.endpoints;
export const {
  useGetAccountsListQuery,
  useLazyGetAccountsListQuery,
  useGetAccountQuery,
  useUpdateAccountMutation,
  useGetAccountUsersQuery,
  useUpdateAccountUserAccessMutation,
  useCreateAccountMutation,
  useInviteUserMutation,
  useRevokeInviteMutation,
  useResendInviteMutation,
  useGetApplePayDomainsQuery,
  useAddApplePayDomainMutation,
  useRemoveApplePayDomainMutation,
} = accountsApi;
export default accountsApi;
