import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response } from 'types/http';

import { getDefaultPagination } from '../../utils/pagination';
import { fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';
import { PayoutInfoResponse, PayoutsListQuery, PayoutsListResponse } from './types';

const REDUCER_PATH = 'payoutsApi';

export const payoutsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.PAYOUT_LIST],
  endpoints: (builder) => ({
    getPayoutsList: builder.query<PayoutsListResponse, PayoutsListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Payouts.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<PayoutsListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination(), requestId: response.requestId };
        }

        return response.data;
      },
      providesTags: [TAGS.PAYOUT_LIST],
    }),
    getPayout: builder.query<PayoutInfoResponse, string>({
      query: (uuid) => Endpoint.Payouts.ById(uuid),
    }),
  }),
});

export const { useGetPayoutsListQuery, useGetPayoutQuery } = payoutsApi;
export default payoutsApi;
