import { PageWrapper } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetPayoutQuery } from 'services/payouts';

import LoadingIndicator from 'components/loadingIndicator';

import RoutePath from 'router/path';

import { useRedirectOnAccountSwitch } from '../../utils/useRedirectOnAccountSwitch';
import { LABELS } from './keys';
import { PayoutDetails } from './payoutDetails';

export const PayoutViewContainer = () => {
  const { t } = useTranslation();
  useRedirectOnAccountSwitch(RoutePath.Pwc.Orders.Root);

  const params = useParams();
  const { uuid } = params;

  const { data: payout, isLoading } = useGetPayoutQuery(uuid!, {
    skip: !uuid,
  });

  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  if (!payout?.data) {
    return null;
  }

  return (
    <PageWrapper breadcrumbs={breadcrumbs} pageTitle={t(LABELS.PAGE_TITLE)}>
      <PayoutDetails payoutData={payout?.data} isLoading={isLoading} />
    </PageWrapper>
  );
};
