import { UrlParam } from 'types/base';

export const Endpoint = {
  Orders: {
    List: 'v1/orders/list',
    CSV: 'v1/orders/csv',
    OrderById: (uuid: UrlParam) => `v1/orders/${uuid}`,
    export: 'v1/orders/csv',
  },
  Clients: {
    List: 'v1/clients/list',
    ById: (uuid: UrlParam) => `v1/clients/${uuid}`,
    Status: (uuid: UrlParam) => `v1/clients/${uuid}/status`,
    Balances: (uuid: UrlParam) => `v1/clients/${uuid}/balances`,
    RefreshKYCStatus: (uuid: UrlParam) => `v1/clients/${uuid}/refresh-kyc`,
    Cards: (uuid: UrlParam) => `v1/clients/${uuid}/cards`,
    IncreaseKYCAttempts: (uuid: UrlParam) => `v1/clients/${uuid}/increase-kyc-attempts`,
    GetQuote: (uuid: UrlParam) => `v1/clients/${uuid}/preview-refund`,
    Refund: (uuid: UrlParam) => `v1/clients/${uuid}/refund`,
    GetOrderPreview: (uuid: UrlParam) => `v1/clients/${uuid}/preview-order`,
    CreateOrder: (uuid: UrlParam) => `v1/clients/${uuid}/order`,
  },
  Users: {
    List: 'v1/users/list',
    Invite: 'v1/users/invite',
    ResendInvite: 'v1/users/invite/resend',
    RevokeAccess: 'v1/users/invite/revoke',
    ChangePassword: 'v1/users/change-password',
    AcceptInvite: (key: UrlParam) => `v1/users/invite/accept?inviteKey=${key}`,
    Create: 'v1/users',
    ById: (uuid: UrlParam) => `v1/users/${uuid}`,
  },
  ApiKeys: {
    ApiKeysByAccountId: (accountUuid: UrlParam) => `v1/accounts/${accountUuid}/api-keys`,
    RotateApiKeyForAccount: (accountUuid: UrlParam) => `v1/accounts/${accountUuid}/api-keys/rotate`,
  },
  Accounts: {
    List: 'v1/accounts/list',
    Available: '/v1/accounts/available',
    ById: (uuid: UrlParam) => `v1/accounts/${uuid}`,
    UserByAccountId: (uuid: UrlParam) => `v1/accounts/${uuid}/users`,
    AccountUserAccessLevel: (uuid: UrlParam, userUuid: UrlParam) => `v1/accounts/${uuid}/users/${userUuid}/access-level`,
    ApplePayDomains: (uuid: UrlParam) => `v1/accounts/${uuid}/apple-pay-domains`,
  },
  Merchants: {
    List: 'v1/pwc/merchants/list',
    ById: (uuid: UrlParam) => `v1/pwc/merchants/${uuid}`,
    BankAccountsById: (uuid: UrlParam) => `v1/pwc/merchants/${uuid}/bank-accounts`,
  },
  Payouts: {
    List: 'v1/pwc/payouts/list',
    ById: (uuid: UrlParam) => `v1/pwc/payouts/${uuid}`,
  },
  PwcOrders: {
    List: 'v1/pwc/orders/list',
    CSV: 'v1/pwc/orders/csv',
    OrderById: (uuid: UrlParam) => `v1/pwc/orders/${uuid}`,
  },
  StaticAssets: {
    Upload: 'v1/static-assets/upload',
  },
  Assets: {
    List: (isActive: boolean) => `v1/assets${isActive ? '?isActive=true' : ''}`,
    CountriesList: 'v1/countries',
  },
  Profile: {
    Me: 'v1/users/me',
  },
  Reconciliations: {
    List: 'v1/reconciliations/list',
    CSV: (uuid: UrlParam) => `v1/reconciliations/${uuid}/csv`,
    ById: (uuid: UrlParam) => `v1/reconciliations/${uuid}`,
  },
};

export default Endpoint;
