import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PayoutTransactionType } from 'services/payouts/types';

import { DataTableCellProps, TableCell } from 'components/dataTable';
import { CopyMenuItem, MenuItem } from 'components/dataTable/cells';
import { ActionMenu } from 'components/dataTable/cells/actionMenu';

import RoutePath from 'router/path';

import { LABELS } from '../../keys';
import { TransactionDataRow } from '../../types';

export const ActionsCell = ({ row }: DataTableCellProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { source, type } = row as TransactionDataRow;

  const navigateToOrder = (uuid: string) => () => navigate(RoutePath.pwc.orderById(uuid));

  const noop = () => {
    //  for now, do nothing
  };

  return (
    <TableCell>
      <ActionMenu title={type === PayoutTransactionType.ORDER ? t(LABELS.ACTIONS.ORDER.TITLE) : t(LABELS.ACTIONS.MISSING)}>
        <CopyMenuItem value={source}>
          {type === PayoutTransactionType.ORDER ? t(LABELS.ACTIONS.ORDER.COPY_ORDER_ID) : t(LABELS.ACTIONS.MISSING)}
        </CopyMenuItem>
        <MenuItem onClick={type === PayoutTransactionType.ORDER ? navigateToOrder(source) : noop}>
          {type === PayoutTransactionType.ORDER ? t(LABELS.ACTIONS.ORDER.OPEN_DETAILS) : t(LABELS.ACTIONS.MISSING)}
        </MenuItem>
      </ActionMenu>
    </TableCell>
  );
};
