import React from 'react';

import { MerchantViewContainer } from './edit/container';
import { CheckoutSectionContainer } from './edit/sections/checkout/container';
import { GeneralSectionContainer } from './edit/sections/general/container';
import { PayoutsSectionContainer } from './edit/sections/settlement/components/payouts/container';
import { SettlementContainer } from './edit/sections/settlement/container';
import { MerchantsListContainer } from './list/container';

export const MerchantsListRoute = MerchantsListContainer;

export const MerchantViewRoute = MerchantViewContainer;

export const MerchantGeneralSectionRoute = () => (
  <>
    <GeneralSectionContainer />
    <CheckoutSectionContainer />
  </>
);

export const SettlementSectionRoute = () => (
  <>
    <SettlementContainer />
    <PayoutsSectionContainer />
  </>
);
