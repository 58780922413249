import styled from '@emotion/styled/macro';

import { COLUMN_IDS } from './keys';

export const DataTableContainer = styled.div((props) => ({
  [`& [data-column="${COLUMN_IDS.SOURCE_ID}"]`]: {
    minWidth: 150,
    maxWidth: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
