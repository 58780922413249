import React from 'react';

import { DataTableCellProps, TableCell } from 'components/dataTable';

import { PayoutStatusBadge } from '../../../../../../../../payout/components/payoutStatusBadge/payoutStatusBadge';
import { PayoutDataRow } from '../../types';

export const StatusCell = ({ row, column }: DataTableCellProps) => {
  const { status } = row as PayoutDataRow;

  return (
    <TableCell data-column={column.id}>
      <PayoutStatusBadge status={status} />
    </TableCell>
  );
};
