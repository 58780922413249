import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import RoutePath from 'router/path';

import { SortOrder } from 'types/sort';

export const LABELS = {
  PANEL_TITLE: ['pages.account.edit.sections.settlement.payouts.title', 'Payouts'],
  TABLE_COLUMNS: {
    PAYOUT_ID: ['pages.account.edit.sections.settlement.payouts.table.columns.name', 'Payout ID'],
    AMOUNT: ['pages.account.edit.sections.settlement.payouts.table.columns.type', 'Amount'],
    DATE: ['pages.account.edit.sections.settlement.payouts.table.columns.roles', 'Date'],
    STATUS: ['pages.account.edit.sections.settlement.payouts.table.columns.inviteStatus', 'Status'],
    ACTIONS: ['pages.account.edit.sections.settlement.payouts.table.columns.actions', ''],
  },
  ACTIONS: {
    TITLE: ['pages.account.edit.sections.settlement.payouts.actions.title', 'Actions'],
    COPY_PAYOUT_ID: ['pages.account.edit.sections.settlement.payouts.actions.copyPayoutId', 'Copy Payout ID'],
    OPEN_DETAILS: ['pages.account.edit.sections.settlement.payouts.actions.openDetails', 'See Payout Details'],
  },
  MERCHANT_INFO: {
    BALANCE: ['pages.account.edit.sections.settlement.payouts.merchantInfo.balance', 'Current Balance: '],
    DATE: ['pages.account.edit.sections.settlement.payouts.merchantInfo.date', 'Next Payout Date: '],
  },
};

export const COLUMN_IDS = {
  PAYOUT_ID: 'uuid',
  AMOUNT: 'amount',
  STATUS: 'status',
  ACTIONS: 'actions',
  SUBMITTED_AT: 'submittedAt',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.PAYOUT_ID, label: t(LABELS.TABLE_COLUMNS.PAYOUT_ID), sortable: true },
  { id: COLUMN_IDS.AMOUNT, label: t(LABELS.TABLE_COLUMNS.AMOUNT), sortable: true },
  { id: COLUMN_IDS.SUBMITTED_AT, label: t(LABELS.TABLE_COLUMNS.DATE), sortable: true },
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];

export const KEYS = {
  FILTER_KEY: 'payouts',
  TABLE_DATE_FORMAT: 'MMM DD, YYYY HH:mm A',
  MERCHANT_DATE_FORMAT: 'MM/DD/YYYY HH:mm A',
  CURRENCY_PROP: 'currency',
  DEFAULT_TABLE_SORTING: {
    FIELD: COLUMN_IDS.SUBMITTED_AT,
    DIRECTION: SortOrder.desc,
  },
  ROUTE_MATCH: `${RoutePath.Pwc.Accounts.ById}/:route`,
  HARDCODED_CURRENCY: 'USD',
};
