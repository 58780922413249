import { OptionallyVisible } from 'components';
import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import DataTable from 'components/dataTable/dataTable';
import LoadingIndicator from 'components/loadingIndicator';

import { PayoutSectionProps } from '../../types';
import { TransactionCell } from './components/cells';
import { COLUMNS, LABELS } from './keys';
import { DataTableContainer } from './styles';
import { TransactionDataRow } from './types';
import { transformTotalsDataToTableFormat } from './utils';

const Total = ({ payoutData, isLoading }: PayoutSectionProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Panel label={t(LABELS.PANEL_TITLE)}>
        <OptionallyVisible visible={isLoading}>
          <LoadingIndicator padded />
        </OptionallyVisible>
      </Panel>
    );
  }

  const hasTransactions = Boolean(payoutData.totals.length);

  const transactionsRows: TransactionDataRow[] = transformTotalsDataToTableFormat(payoutData.currency, payoutData.totals);

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <OptionallyVisible visible={hasTransactions}>
        <DataTableContainer>
          <DataTable columns={COLUMNS} rows={transactionsRows} CellComponent={TransactionCell} />
        </DataTableContainer>
      </OptionallyVisible>
    </Panel>
  );
};

export const TotalSection = withTranslation()(Total);
