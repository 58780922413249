import { Payout, PayoutsListFilters } from 'services/payouts/types';

import i18n from 'locales/i18next';

import { formatNumberI18n } from 'utils/i18n';

import { PayoutDataRow } from './types';

export const transformPayoutsDataToTableFormat = (payouts?: Payout[]): PayoutDataRow[] => {
  return payouts
    ? payouts.map((payout) => ({
        ...payout,
        id: payout.uuid,
      }))
    : [];
};
export const formatNumber = (stringOrNumber?: string | number) => formatNumberI18n(i18n.language)(Number(stringOrNumber ?? 0));

export const prepareFilters = (filters: PayoutsListFilters): PayoutsListFilters | undefined => {
  if (!filters) {
    return undefined;
  }

  const { merchantUuids } = filters;

  const nextFilters: PayoutsListFilters = {};
  if (merchantUuids?.length) {
    nextFilters.merchantUuids = merchantUuids;
  }

  return nextFilters;
};
