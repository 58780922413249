import styled from '@emotion/styled';
import { Link as ReactLink } from 'react-router-dom';

export const SectionWrapper = styled.div((props) => ({
  display: 'flex',
  padding: 10,
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 15,
  alignSelf: 'stretch',
}));

export const Item = styled.div((props) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 20,
}));

export const ItemName = styled.div((props) => ({
  width: 150,
  minWidth: 150,
  color: props.theme.colors.inactive,
  fontSize: 13,
  fontWeight: 400,
}));

export const ItemValue = styled.div((props) => ({
  fontSize: 13,
  fontWeight: 400,
}));

export const OnChainTransactionHash = styled.div<{ displayFullHash?: boolean }>((props) => ({
  whiteSpace: 'nowrap',
  maxWidth: props.displayFullHash ? 'none' : 250,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: 13,
  fontWeight: 400,
  '& a': {
    color: props.theme.colors.text.accent,
  },
}));

export const Link = styled(ReactLink)((props) => ({
  color: props.theme.colors.accent,
}));

export const PeriodContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 15,
});

export const Details = styled.span((props) => ({
  color: props.theme.colors.inactive,
}));
