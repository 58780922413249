import React from 'react';
import { useGetAccountApiKeysQuery, useRotateApiKeyForAccountMutation } from 'services/apiKeys';

import useGetUuid from '../utils';
import { APIKeysSection } from './apiKeys';
import { ApiKeysTableRow, RotateApiKeyFn } from './types';
import { transformApiKeysDataToTableFormat } from './utils';

export const APIKeysSectionContainer = () => {
  const uuid = useGetUuid();

  const { data, isLoading, isUninitialized } = useGetAccountApiKeysQuery(uuid!, {
    skip: !uuid,
  });
  const [rotateApiKeyWithNoAccountId] = useRotateApiKeyForAccountMutation();

  const transformedApiKeysData: ApiKeysTableRow[] = transformApiKeysDataToTableFormat(data);
  const isPending = isUninitialized || isLoading;

  const rotateApiKey: RotateApiKeyFn = async (requestBody) => {
    await rotateApiKeyWithNoAccountId({
      ...requestBody,
      accountUuid: uuid!,
    });
  };

  return <APIKeysSection apiKeys={transformedApiKeysData} isPending={isPending} rotateApiKey={rotateApiKey} />;
};
