import React from 'react';
import { useTranslation } from 'react-i18next';

import defaultProductImage from 'assets/images/default-product-image.png';

import { Panel } from 'components/containers';
import OptionallyVisible from 'components/optionallyVisible';

import RoutePath from 'router/path';

import { OrderItem as OrderItemType } from 'types/pwcOrder';

import { convertValueToDayjs } from 'utils/date';

import { OrderStatusBadge } from '../../../components/orderStatusBadge/orderStatusBadge';
import { KEYS, LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import {
  AmountBlock,
  AmountNumericValue,
  AmountValue,
  Delimiter,
  Item,
  ItemName,
  ItemValue,
  Link,
  OrderItemDetails,
  OrderItemImage,
  OrderItemName,
  OrderItems,
  SectionWrapper,
} from '../styles';
import { formatCryptoCode, formatNumber } from '../utils';

interface OrderSectionPropsWithUserType extends OrderPwcSectionProps {
  isS4cUser: boolean;
}

const OrderItem = ({ name, imageUrl, fiatAmount, currency }: OrderItemType & { currency: string }) => {
  const { t } = useTranslation();

  return (
    <OrderItemDetails>
      <OrderItemImage src={imageUrl || defaultProductImage} alt={name} /> <OrderItemName>{name}</OrderItemName> {t(KEYS.DASH)}
      <span>
        {fiatAmount} {currency.toUpperCase()}
      </span>
    </OrderItemDetails>
  );
};

export const OrderSection = ({ orderData, isS4cUser }: OrderSectionPropsWithUserType) => {
  const { t } = useTranslation();

  return (
    <Panel label={t(LABELS.SECTIONS.ORDER.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.ORDER_ID)}</ItemName>
          <ItemValue>{orderData.general.uuid}</ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.general.externalOrderId)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.EXTERNAL_ORDER_ID)}</ItemName>
            <ItemValue>{orderData.general.externalOrderId}</ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={isS4cUser}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.MERCHANT_NAME)}</ItemName>
            <ItemValue>
              <Link to={RoutePath.pwc.accountById(orderData.merchant.uuid)}>{orderData.merchant.name}</Link>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.CREATION_TIME)}</ItemName>
          <ItemValue>{convertValueToDayjs(orderData.general.createdAt)?.format(KEYS.DATE_FORMAT)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.EXPIRATION_TIME)}</ItemName>
          <ItemValue>{convertValueToDayjs(orderData.general.expiredAt)?.format(KEYS.DATE_FORMAT)}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.STATUS)}</ItemName>
          <ItemValue>
            <OrderStatusBadge status={orderData.general.status} />
          </ItemValue>
        </Item>

        <Delimiter />

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.CRYPTO_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <OptionallyVisible visible={Boolean(orderData.general.cryptoAmount)}>
                <AmountValue>{formatCryptoCode(orderData.general.cryptoCurrencyCode)}</AmountValue>
              </OptionallyVisible>
              <AmountNumericValue>{orderData.general.cryptoAmount ? orderData.general.cryptoAmount : t(KEYS.DASH)}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.general.fiatAmount)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.FIAT_AMOUNT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.general.fiatCurrencyCode?.toUpperCase()}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.general.fiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.PROCESSING_FEE_PERCENT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountNumericValue>{formatNumber(orderData.general.processingFeePercent)}</AmountNumericValue>
              <AmountValue>%</AmountValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.ORDER.PROCESSING_FEE_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{orderData.general.fiatCurrencyCode?.toUpperCase()}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.general.processingFeeAmount)}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(orderData.general.cryptoPrice)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.CRYPTO_PRICE)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountNumericValue>{KEYS.BASE_CURRENCY_UNIT}</AmountNumericValue>
                <AmountValue>{formatCryptoCode(orderData.general.cryptoCurrencyCode)}</AmountValue>
                <AmountValue>{KEYS.APPROX_EQUAL_SYMBOL}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.general.cryptoPrice)}</AmountNumericValue>
                <AmountValue>{orderData.general.fiatCurrencyCode}</AmountValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.general?.items?.length) || Boolean(orderData.general.redirectUrl)}>
          <Delimiter />
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.general?.items?.length)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.ITEMS)}</ItemName>
            <ItemValue>
              <OrderItems>
                {orderData.general?.items?.map((item) => (
                  <OrderItem key={item.uuid} {...item} currency={orderData.general.fiatCurrencyCode!} />
                ))}
              </OrderItems>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.general.redirectUrl)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.ORDER.REDIRECT_URL)}</ItemName>
            <ItemValue>{orderData.general.redirectUrl}</ItemValue>
          </Item>
        </OptionallyVisible>
      </SectionWrapper>
    </Panel>
  );
};
