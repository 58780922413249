import React from 'react';

import { Path, Svg } from './styles';
import { IconProps } from './types';

const path = [
  'M8.63333 9.45L6.41667 7.23333V4.08333H7.58333V6.76667L9.45 8.63333L8.63333 9.45ZM7 12.25C6.27083',
  '12.25 5.58794 12.1116 4.95133 11.8347C4.31433 11.5574 3.76017 11.1829 3.28883 10.7112C2.81711',
  '10.2398 2.44261 9.68567 2.16533 9.04867C1.88844 8.41206 1.75 7.72917 1.75 7C1.75 6.27083',
  '1.88844 5.58775 2.16533 4.95075C2.44261 4.31414 2.81711 3.75997 3.28883 3.28825C3.76017 ',
  '2.81692 4.31433 2.44261 4.95133 2.16533C5.58794 1.88844 6.27083 1.75 7 1.75C7.79722 1.75',
  '8.55322 1.92014 9.268 2.26042C9.98239 2.60069 10.5875 3.08194 11.0833',
  '3.70417V2.33333H12.25V5.83333H8.75V4.66667H10.3542C9.95556 4.12222 9.46458 3.69444 8.88125',
  '3.38333C8.29792 3.07222 7.67083 2.91667 7 2.91667C5.8625 2.91667 4.89767 3.31275 4.1055 4.10492C3.31294',
  '4.89747 2.91667 5.8625 2.91667 7C2.91667 8.1375 3.31294 9.10233 4.1055 9.8945C4.89767 10.6871 5.8625',
  '11.0833 7 11.0833C8.02083 11.0833 8.91275 10.7528 9.67575 10.0917C10.4391 9.43056 10.8889 8.59445',
  '11.025 7.58333H12.2208C12.075 8.91528 11.5039 10.0259 10.5076 10.9153C9.51086 11.8051 8.34167 12.25 7 12.25Z',
].join(' ');

export const IconUpdate24 = ({ width = 14, height = 14, className }: IconProps) => (
  <Svg className={className} width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path fillRule="evenodd" clipRule="evenodd" d={path} />
  </Svg>
);

export default IconUpdate24;
