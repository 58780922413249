import React from 'react';

import { DataTableCellProps, TableCell } from 'components/dataTable';

import { TransactionDataRow } from '../../types';
import { formatNumber } from '../../utils';

export const CurrencyCell = ({ row, column }: DataTableCellProps) => {
  const value = row[column.id];

  return (
    <TableCell data-column={column.id}>
      {formatNumber(value)} {(row as TransactionDataRow).currency.toUpperCase()}
    </TableCell>
  );
};
