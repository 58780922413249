import { PayoutPublicModel } from 'services/payouts/types';

import i18n from 'locales/i18next';

import { formatNumberI18n } from 'utils/i18n';

import { TransactionDataRow } from './types';

export const transformTransactionsDataToTableFormat = (
  currency: PayoutPublicModel['currency'],
  transactions?: PayoutPublicModel['transactions'],
): TransactionDataRow[] => {
  return transactions
    ? transactions.map((payout) => ({
        ...payout,
        id: payout.source,
        currency,
      }))
    : [];
};
export const formatNumber = (stringOrNumber?: string | number) => formatNumberI18n(i18n.language)(Number(stringOrNumber ?? 0));
